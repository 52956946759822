import 'react-app-polyfill/stable';
import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './app/store';
import { PersistGate } from 'redux-persist/integration/react';
import persistStore from 'redux-persist/es/persistStore';

import 'pace-js/themes/green/pace-theme-minimal.css';
import { IntlProvider } from 'react-intl';

const messages_en = {
  'app.text': 'Edit <code>src/App.js</code> and save to reload.<br/>Now with {what}!',
  'app.learn-react-link': 'Learn React.',
  'app.name': 'Name',
};
const messages_de = {
  'app.text':
    'Bearbeite und speichere <code>src/App.js</code> um diese Seite neu zu laden.<br/>Nun mit {what}!',
  'app.learn-react-link': 'Lerne React.',
  'app.name': 'Nombre',
};

const messages = {
  de: messages_de,
  en: messages_en,
};
const language = 'de'; // language without region code
console.log('language', language);
let persist = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persist}>
      <IntlProvider locale={language} messages={messages[language]}>
        <App />
      </IntlProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
