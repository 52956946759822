import { commonApi } from './commonApi';

export const licenseApi = commonApi.injectEndpoints({
  reducerPath: 'licenseApi',
  overrideExisting: true,
  tagTypes: ['License'],
  endpoints: (builder) => ({
    verifyCode: builder.mutation({
      query: (data) => ({
        url: 'verify-code',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['License'],
    }),
  }),
});

export const { useVerifyCodeMutation } = licenseApi;
