import { commonApi } from './commonApi';

export const userApi = commonApi.injectEndpoints({
  reducerPath: 'userApi',
  tagTypes: ['User'],
  endpoints: (builder) => ({
    users: builder.query({
      query: () => '/user',
      providesTags: ['User'],
    }),
    getUserByIdQuery: builder.query({
      query: (id) => `/user/${id}`,
      providesTags: ['User'],
    }),
    addUser: builder.mutation({
      query: (user) => ({
        url: '/user',
        method: 'POST',
        body: user,
      }),
      invalidatesTags: ['User'],
    }),
    updateUser: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/user/${id}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: ['User'],
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/user/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
  }),
});

export const {
  useUsersQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetUserByIdQueryQuery,
} = userApi;
