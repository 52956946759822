import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sidebarShow: true,
  unfoldable: false,
  balanceShow: false,
};
const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    sidebarShowHide: (state, { payload }) => {
      state.sidebarShow = !state.sidebarShow;
    },
    sidebarUnfoldable: (state) => {
      state.unfoldable = !state.unfoldable;
    },
    balanceShowHide: (state) => {
      state.balanceShow = !state.balanceShow;
    },
  },
});

export const { sidebarShowHide, sidebarUnfoldable, balanceShowHide } = globalSlice.actions;
export default globalSlice.reducer;
