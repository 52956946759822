import { commonApi } from './commonApi';

export const RTableApi = commonApi.injectEndpoints({
  reducerPath: 'RTableApi',
  overrideExisting: true,
  tagTypes: ['Data'],
  endpoints: (builder) => ({
    getData: builder.query({
      query: ({ endpoint, fields, page = 1, limit = 1000 }) => {
        if (!fields) {
          return `${endpoint}?limit=${limit}&offset=${(page - 1) * limit}`;
        }

        return `${endpoint}?fields=${fields}&limit=${limit}&offset=${(page - 1) * limit}`;
      },
      providesTags: ['Data'],
    }),

    addData: builder.mutation({
      query: ({ endpoint, data }) => ({
        url: `/${endpoint}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Data'],
    }),

    getDataById: builder.query({
      query: ({ endpoint, id }) => `${endpoint}/${id}`,

      invalidatesTags: ['Data'],
    }),

    updateData: builder.mutation({
      query: ({ endpoint, id, ...rest }) => ({
        url: `${endpoint}/${id}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: ['Data'],
    }),

    deleteData: builder.mutation({
      query: ({ endpoint, id }) => ({
        url: `${endpoint}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Data'],
    }),
  }),
});

export const {
  useGetDataQuery,
  useAddDataMutation,
  useDeleteDataMutation,
  useGetDataByIdQuery,
  useUpdateDataMutation,
} = RTableApi;
