import { configureStore } from '@reduxjs/toolkit';
import AuthSlice from '../features/authSlice';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import globalSlice from '../features/globalSlice';
import { userApi } from '../services/userApi';
import { commonApi } from 'src/services/commonApi';
import { RTableApi } from 'src/services/RTableApi';
import { licenseApi } from 'src/services/licenseApi';

const reducers = combineReducers({
  global: globalSlice,
  auth: AuthSlice,
  [RTableApi.reducerPath]: RTableApi.reducer,
  [licenseApi.reducerPath]: licenseApi.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'global'], // only auth will be persisted
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      userApi.middleware,
      commonApi.middleware,
      RTableApi.middleware,
      licenseApi.middleware,
    ]);
  },
});

export default store;
